import React, { lazy, Suspense, useEffect } from 'react'
import { Header } from './components/Header'
import './scss/styles.scss'
import './scss/index-styles.scss'
import './scss/quiz-results-styles.scss'
import './scss/quiz-styles.scss'
import { useLocation } from 'react-router-dom'
import Helmet from 'react-helmet'
import ogImage from './img/I_am_Train_Safe_Open_Graph.jpg'
import videoPlaceholder from "./img/nr-hero-hp.jpg";
import videoPlaceholderTablet from "./img/nr-hero-hp-tablet_720.jpg";

const Body = lazy(() => import('./components/Body'))
const Footer = lazy(() => import('./components/Footer'))


function App() {
  const location = useLocation()
  const show = location.pathname !== '/quiz'

  const textMenu = (location.pathname === '/terms' || location.pathname === '/privacy' || location.pathname === '/accessibility')
  const hideAllTopLinks = false; //Used to show the header where these might not be defined yet.
  const hideOnPageBottomLinks = false; //Used to show the footer where some on page content might not be rendered.

  const isResultsPage = location.pathname === '/results'

  useEffect(() => {
    window.document.body.scrollTo(0,0);
  }, [location])

  const doc = document.documentElement;

  const appHeight = () => {
    setTimeout(() => {
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }, 200);
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  document.addEventListener('resize', appHeight)
  window.addEventListener('orientationchange', appHeight)

  const loadCookieConsent = () => {
    if (!window.cookieconsent) {
      window.setTimeout(() => {
        loadCookieConsent();
      }, 500)
    } else {
      window.cookieconsent.run({"notice_banner_type":"simple","consent_type":"express","palette":"dark","language":"en","page_load_consent_levels":["strictly-necessary"],"notice_banner_reject_button_hide":false,"preferences_center_close_button_hide":false,"website_name":"I am train safe","website_privacy_policy_url":`${process.env.REACT_APP_CANONICAL_URL}/privacy`});
    }
  }


  document.addEventListener('DOMContentLoaded', () => {
    loadCookieConsent()
  })

  useEffect(appHeight);

  //GA Tracking ID
  const trackingId = 'UA-197742943-1';

  const gaScript = process.env.REACT_APP_APP_ENV === 'production' ?
    <script async type="text/plain" cookie-consent="tracking" src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`} /> : ``;

  const gaTrackingCode = process.env.REACT_APP_APP_ENV === 'production' ?
    <script>
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${trackingId}');
        `}
    </script> : ``;

  const cookieConsentCode =
      <script type="text/javascript" src="//www.freeprivacypolicy.com/public/cookie-consent/4.0.0/cookie-consent.js" charset="UTF-8"/>
    ;

  const googleTagManagerCode = process.env.REACT_APP_APP_ENV === 'production' ?
    <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MLFLL26');`}</script> : ``
  ;


  return (
    <Suspense fallback={'loading'}>
      <Helmet>
        <link rel="preload" as="image" href={videoPlaceholder} />
        <link rel="preload" as="image" href={videoPlaceholderTablet} />
        {googleTagManagerCode}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Challenge Gareth Thomas and Joanna Page in Network Rail’s Welsh Train Safety Quiz. Let’s see who’s train safe and who needs to wise up. Make the pledge to be train safe today."
        />
        {process.env.REACT_APP_APP_ENV !== 'production' ? <meta name="robots" content="noindex,nofollow" /> : <meta name="robots" content="index,nofollow" />}
        <meta property="og:title" content="I AM TRAIN SAFE" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:description" content="Challenge Gareth Thomas and Joanna Page in Network Rail’s Welsh Train Safety Quiz." />
        <meta property="og:url" content={process.env.REACT_APP_CANONICAL_URL} />
        <title>Network Rail’s Welsh Train Safety Quiz | I am train safe</title>
        <link rel="canonical" href={process.env.REACT_APP_CANONICAL_URL} />
        <link rel="preconnect" href="https://www.youtube.com" />
        {gaScript}
        {gaTrackingCode}
        {cookieConsentCode}
      </Helmet>
      {(process.env.REACT_APP_APP_ENV === 'production') && (
        <noscript>
          <iframe title={`gtm`} src="https://www.googletagmanager.com/ns.html?id=GTM-MLFLL26" height="0" width="0" style={{display: 'none', visibility: 'hidden'}} />
        </noscript>
      )}
      {show && <Header textMenu={textMenu} hideAllTopLinks={hideAllTopLinks} resultsPage={isResultsPage} />}
      <Body />
      {show && <Footer textMenu={textMenu} hideOnPageBottomLinks={hideOnPageBottomLinks}  />}
    </Suspense>
  )
}

export default App
