import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../img/nr-logo.png'
import { useTranslation } from "react-i18next"

export function Header({textMenu, resultsPage, hideAllTopLinks}) {
  const [t] = useTranslation('common')
  const [hidden, setHidden] = useState(false);

  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    setHidden(top > 250);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useEffect(() => {
    window.YTConfig = {
      host: 'https://www.youtube.com'
    }
  }, []);

  const scrollToSection = (e, id) => {
    document.getElementById('menu-btn').click()
    if (id === 'pledge') {
      e.preventDefault()
      let el = document.getElementById(id)
      el.scrollIntoView({behavior: 'smooth'})
    } else if (id === 'youtube') {
      e.preventDefault();
      let el = document.getElementById(id)
      el.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <header className={`header ${hidden ? 'hide' : ''}`}>
      <div className="logo">
        <Link to={'/'}>
          <img src={logo} alt="Network Rail Logo" />
        </Link>
      </div>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon" />
      </label>
      <ul className="menu">
        {(textMenu || resultsPage || hideAllTopLinks) && <li>
          <Link className="plain" to="/">
            {t('home')}
          </Link>
        </li>}
        {!hideAllTopLinks && <li>
          <Link className="plain" to="/quiz">
            {t('menu.link1')}
          </Link>
        </li>}
        {(!textMenu && !hideAllTopLinks) && <li>
          <a
            className="plain"
            onClick={(e) => scrollToSection(e, 'pledge')}
            href="#pledge"
          >
            {t('menu.link2')}
          </a>
        </li>}
        {(!textMenu && !hideAllTopLinks) && <li>
          <a
            className="plain"
            href="#youtube"
            onClick={(e) => scrollToSection(e, 'youtube')}
          >
            {t('menu.link3')}
          </a>
        </li>}
      </ul>
    </header>
  )
}
