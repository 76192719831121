import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_eng from './translations/eng/common.json'
import common_cym from './translations/cym/common.json'

import home_hero_section_eng from './translations/eng/home-hero-section.json'
import home_hero_section_cym from './translations/cym/home-hero-section.json'

import quotes_section_eng from './translations/eng/quotes-section.json'
import quotes_section_cym from './translations/cym/quotes-section.json'

import footer_eng from './translations/eng/footer.json'
import footer_cym from './translations/cym/footer.json'

import video_section_eng from './translations/eng/video-section.json'
import video_section_cym from './translations/cym/video-section.json'

import pledge_section_eng from './translations/eng/pledge-section.json'
import pledge_section_cym from './translations/cym/pledge-section.json'

const lang = localStorage.getItem('nr-safety-lang') || 'eng'

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  // lng: 'eng',
  lang: 'eng',
  lng: lang,
  resources: {
    eng: {
      common: common_eng,
      'home-hero-section': home_hero_section_eng,
      'quotes-section': quotes_section_eng,
      footer: footer_eng,
      'video-section': video_section_eng,
      'pledge-section': pledge_section_eng,
    },
    cym: {
      common: common_cym,
      'home-hero-section': home_hero_section_cym,
      'quotes-section': quotes_section_cym,
      footer: footer_cym,
      'video-section': video_section_cym,
      'pledge-section': pledge_section_cym,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
